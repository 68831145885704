import React, { useState } from "react";
import t from '../../lib/lng';

const ResendConfirmAccountNotify = ({ close }) => {
	const [linkSent, setLinkSent] = useState(false);
	const onClick = async() => {
		await fetch('/send-email-confirm/?next=' + location.pathname);
		if (window.location.pathname.match(/project|setup-style/) && window.sessionStorage.getItem('genState')) {
			window.localStorage.setItem('genState', window.sessionStorage.getItem('genState'));
		}
		setLinkSent(true);
	}
	return(
		<div className="modal">
			<div className="modal_overlay" onClick={close}></div>
			<div className="modal_notify">
				<div className="modal_close" onClick={close}>
					<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<line y1="-1" x2="21.2624" y2="-1" transform="matrix(0.706015 0.708197 -0.706015 0.708197 1 2.35706)" stroke="#f8f8fb" strokeWidth="2"/>
						<line y1="-1" x2="21.2624" y2="-1" transform="matrix(-0.706015 0.708197 0.706015 0.708197 16.7895 2.35706)" stroke="#f8f8fb" strokeWidth="2"/>
					</svg>
				</div>
				<img className="modal_notify_img" src="/static/i/w14.webp" alt="" />
					{
						!linkSent &&
						<>
							<div className="modal_notify_msg">{t.formatString(t.confirmeAccountMsg, <br/>)}</div>
							<div className="about_btn" onClick={onClick}>{t.resendEmail}</div>
						</>
					}
					{
						linkSent &&
						<>
							<div className="modal_notify_msg">{t.formatString(t.weSentYouValidationLink, <br/>)}</div>
							<div className="about_btn" onClick={close}>{t.OK}</div>
						</>
					}
			</div>
		</div>
	);
}

export default ResendConfirmAccountNotify;