import { cookie } from "../../../lib/utils";

const registerAnalytics = {
    userRegistered: ({ method, userId }) => {
        let accountsOnDevice = 1;

        if (window.localStorage.getItem('accounts')) {
            const accounts = JSON.parse(window.localStorage.getItem('accounts'));
            if (!accounts.includes(userId)) {
                accounts.push(userId);
                window.localStorage.setItem('accounts', JSON.stringify(accounts));
            }
            accountsOnDevice = accounts.length;
        }
        else {
            window.localStorage.setItem('accounts', '['+userId+']');
        }
        const reg = JSON.parse(window.sessionStorage.getItem('reg'));
        const useCase = localStorage.getItem('reg_quiz') ?? 'skip';
        localStorage.removeItem('reg_quiz');
        if(useCase !== 'skip') document.cookie = `user_usecase=${useCase}; path=/;`;
        if (typeof(dataLayer) !== 'undefined') {
            dataLayer.push({
                ...{
                    event: 'user_data',
                    user_id:userId,
                }, ...(useCase !== 'skip' ? { user_usecase: useCase } : {}),
            });
            dataLayer.push({
                event: 'sign_up',
                method: method,
                usecase: useCase
            });
            dataLayer.push({
                event: 'trial_start'
            });
        }
        amplitude.getInstance().logEvent('service registration', {
            'USER ID': userId,
            'registration_by': method,
            'send_marketing_email': reg ? reg.send_marketing_email : 'allow',
            'accounts_on_device': accountsOnDevice
        });
        cookie.delete('oauth_reg', '/');
        window.sessionStorage.removeItem('reg');
        window.sessionStorage.removeItem('auth_by_google');
        window.localStorage.removeItem('ph_support_shown');
    }
}

export default registerAnalytics;