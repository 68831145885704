import { CANNY_DEFAULT_USER, CANNY_KEY, CANNY_URL } from "../utils/constants";

export function useCannyLink(user_ctx){
	function routeLinkCanny(event) {
		event.preventDefault();
		 (function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}})(window,document,"canny-jssdk","script");
		Canny('identify', {
			appID: CANNY_KEY,
			url: CANNY_URL,
			user:user_ctx ? {
				email: user_ctx.username,
				name: user_ctx.username,
				id: user_ctx.id,
		}: CANNY_DEFAULT_USER}, () => {
			if(event.target?.href?.match(/clientToken/g)){
				window.open(event.target.href, '_blank');
			}else{
				event.target.click()
			}
		});
	}
	return routeLinkCanny;
}