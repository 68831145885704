import React from 'react';
import t from '../../lib/lng';
import SystemMessage from './SystemMessage';

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// setTimeout(() => {
		// 	location.reload();
		// }, 3000);
	}

	render() {
		if (this.state.hasError) {
			return(
				<div className="gen">
					<header className="gen_header">
						<a className="gen_logo" href="/"></a>
					</header>
					<div className="gen_main step0">
						<div className="gen_content">
							{/* <div className="about_btn reload_page_btn" onClick={() => {location.reload()}}>{t.pleaseReloadPageAndTryAgain}</div> */}
						</div>
						<div className="smsg_wrap">
							<SystemMessage systemMessage={{msg: t.oopsSomethingBroke, type: 'error'}} onClick={() => {location.reload()}} />
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}