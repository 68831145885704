import React, {createContext, useContext, useState} from 'react';
import {createPortal} from "react-dom";
import SystemMessage from "../common/SystemMessage";

export const toastContext = createContext({});
export const useToastContext = () => useContext(toastContext);

const useToast = () => {
    const [toast, setToast] = useState([]);
    return {
        toast, getToast: (t) => setToast((prev) => [...prev, t]), setToast,
    }
}

export const ToastProvider = ({children}) => {
    const toast = useToast();
    return  (
        <>
            <toastContext.Provider value={toast}>
                {children}
                <ToastContainer toastState={toast}/>
            </toastContext.Provider>
        </>

    )
}

const ToastContainer = ({toastState}) => {
    const removeToast = (index) => {
        toastState.toast()[index]?.onClick?.();
        toastState.setToast((prevState) => {
            prevState.splice(index, 1);
            return [];
        })
    }
    return (
        <>
            {toastState.toast.length &&
                    createPortal(
                        toastState.toast.map(
                            (systemMessage, index) =>
                                (<SystemMessage key={index} {...{...systemMessage, onClick: () => removeToast(index)}} />)
                        ), document.getElementById('toaster')
                    )}
       </>
    );
};

export default ToastContainer;