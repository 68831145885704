import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from "react-router";

import Footer from "../../../common/footer";

const WithoutHeaderLayout = (props) => {
    const { context, updateData } = props;
    return (
        <div className="main">
            <Outlet context={{context, updateData}} />
            <Footer />
        </div>
    );
};

WithoutHeaderLayout.prototype = {
    updateData: PropTypes.func,
    context: PropTypes.object,
}

export default WithoutHeaderLayout;