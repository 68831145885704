import React from 'react';
import { Outlet } from "react-router";
import PropTypes from "prop-types";

import Header from "../../../common/header";
import Footer from "../../../common/footer";

const DefaultLayout = (props) => {
    const { updateData, context } = props;
    return (
        <div>
            <Header context={context} updateData={updateData} />
                <main><Outlet context={{context, updateData}}/></main>
            <Footer/>
        </div>
    );
};

DefaultLayout.prototype = {
    updateData: PropTypes.func,
    context: PropTypes.object,
}

export default DefaultLayout;